export const cep = {
    namespaced: true,
    state: {
      states: [],
      selectedState: null,
      selectedCity: null
    },
    mutations: {
      setStates(state, states) {
        state.states = Object.values(states);
      },
      setSelectedState(state, selectedState) {
        state.selectedState = selectedState;
      },
      setSelectedCity(state, selectedCity) {
        state.selectedCity = selectedCity;
      }
    },
    actions: {
      async fetchLocationData({ commit }) {
        try {
          const response = await import('./states.json');
          const states = response.default;
          commit('setStates', states);
        } catch (error) {
          console.error('Error fetching location data:', error);
        }
      },
      selectState({ commit }, selectedState) {
        commit('setSelectedState', selectedState);
        commit('setSelectedCity', null);
      },
      selectCity({ commit }, selectedCity) {
        commit('setSelectedCity', selectedCity);
      }
    },
    getters: {
      getStateNames: (state) => state.states.map(i => i.name),
      getCitiesBySelectedState: state => {
        const selectedState = state.states.find(i => i.name === state.selectedState);
        return selectedState ? selectedState.cities : [];
      },
      getAbbreviationBySelectedState: state => {
        const selectedState = state.states.find(i => i.name === state.selectedState);
        return selectedState ? selectedState.abbreviation : '';
      },
      getAbbreviationByState: state => (stateName) => {
        const selectedState = state.states.find(i => i.name === stateName);
        return selectedState ? selectedState.abbreviation : '';
      },
      getStateByAbbreviation: state => (abbreviation) => {
        const selectedState = state.states.find(i => i.abbreviation === abbreviation);
        return selectedState ? selectedState.name : '';
      }
    }
  };