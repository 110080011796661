const routes = [
    {
        path: '/:ctg/judge',
        name: 'judge.view',
        component: () => import('../views/judge/main/MainHolder.vue'),
        meta: { title: 'Listagem de eventos', isJudge: true, needAuth: true },
        children: [
            {
                path: 'today',
                name: 'judge.events.list.view',
                component: () => import('../views/judge/main/IndexView.vue'),
                meta: { title: 'Listagem de eventos', isJudge: true, needAuth: true },
            },
            {
                path: 'event/:event/trial/:trial/details',
                name: 'judge.trial.detail.view',
                component: () => import('../views/judge/main/TrialDetailsView.vue'),
                meta: { title: 'Listagem de eventos', isJudge: true, needAuth: true },
            },
        ]
    },
];

export default routes;