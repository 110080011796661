export const monetaryMixin = {
    methods: {
        toBRL(value, showCurrency = false) {
            const formatter = new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
            });

            if (showCurrency) return formatter.format(value)

            return formatter.format(value).slice(3);
        },

        toFloat(string) {
            if (string.startsWith('R$')) string = string.slice(3);

            string = string.replace(/\./g, '');
            const decimal = string.split(',');

            if (decimal.length > 1) {    
                const decimalString = decimal[0] + '.' + decimal[1];

                return parseFloat(decimalString);
            }

            return parseFloat(string);
        }
    }
}