const routes = [
    {
        path: '/:ctg/patron',
        name: 'patron.view',
        component: () => import('../views/user/main/MainHolder.vue'),
        meta: { title: 'Listagem de eventos', isPatron: true, needAuth: true },
        children: [
            {
                path: 'events',
                name: 'patron.events.list.view',
                component: () => import('../views/user/main/EventsListView.vue'),
                meta: { title: 'Listagem de eventos', isPatron: true, needAuth: true },
            },
            {
                path: 'event/:id/financial',
                name: 'patron.event.financial.view',
                component: () => import('../views/user/main/EventFinancialView.vue'),
                meta: { title: 'Relatório financeiro', isPatron: true, needAuth: true },
            },
            {
                path: 'event/new',
                name: 'patron.event.new.view',
                component: () => import('../views/user/main/EventCreateView.vue'),
                meta: { title: 'Novo evento', isPatron: true, needAuth: true },
            },
            {
                path: 'event/:id',
                name: 'patron.event.edit.view',
                component: () => import('../views/user/main/EventCreateView.vue'),
                meta: { title: 'Editar evento', isPatron: true, needAuth: true },
            },
            {
                path: 'users',
                name: 'patron.users.list.view',
                component: () => import('../views/user/main/UserManagement.vue'),
                meta: { title: 'Gestão de usuários', isPatron: true, needAuth: true },
            },
            {
                path: 'profile',
                name: 'patron.profile.view',
                component: () => import('../views/user/main/ProfileView.vue'),
                meta: { title: 'Meus dados', isPatron: true, needAuth: true },
            },
            {
                path: 'users/new',
                name: 'patron.users.new.view',
                component: () => import('../views/user/main/UserCreateView.vue'),
                meta: { title: 'Gestão de usuários', isPatron: true, needAuth: true },
            },
            {
                path: 'users/id/:id',
                name: 'patron.users.edit.view',
                component: () => import('../views/user/main/UserCreateView.vue'),
                meta: { title: 'Gestão de usuários', isPatron: true, needAuth: true },
            },
        ]
    },
];

export default routes;