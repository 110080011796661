import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL;

class AuthService {
    login(user) {
        return axios
            .post(API_URL + '/api/auth/login', {
                email: user.email,
                password: user.password
            })
            .then(response => {
                if (response.data.access_token) {
                    localStorage.setItem('access_token', JSON.stringify(response.data.access_token));
                    localStorage.setItem('user', JSON.stringify(response.data))
                }

                return response.data;
            });
    }

    logout() {
        localStorage.removeItem('access_token');
        localStorage.removeItem('user');
    }

    parseJwt(token) {
        let base64Url = token.split('.')[1];
        let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        let jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    }
}

export default new AuthService();