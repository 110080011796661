const routes = [
    {
        path: '/:ctg/secretary',
        name: 'secretary.view',
        component: () => import('../views/secretary/main/MainHolder.vue'),
        meta: { title: 'Listagem de eventos', isSecretary: true, needAuth: true },
        children: [
            {
                path: 'events',
                name: 'secretary.events.list.view',
                component: () => import('../views/secretary/main/IndexView.vue'),
                meta: { title: 'Listagem de eventos', isSecretary: true, needAuth: true },
            },
            {
                path: 'events/:event/participants',
                name: 'secretary.events.participants.view',
                component: () => import('../views/secretary/main/TrialParticipantsView.vue'),
                meta: { title: 'Listagem de eventos', isSecretary: true, needAuth: true },
            },
            {
                path: 'events/:event/participants/:group/details',
                name: 'secretary.events.group.details.view',
                component: () => import('../views/secretary/main/ParticipantsDetailsView.vue'),
                meta: { title: 'Listagem de eventos', isSecretary: true, needAuth: true },
            },
            {
                path: 'events/:event/purchase',
                name: 'secretary.events.purchase.view',
                component: () => import('../views/secretary/main/TrialPurchaseView.vue'),
                meta: { title: 'Listagem de eventos', isSecretary: true, needAuth: true },
            },
        ]
    },
];

export default routes;