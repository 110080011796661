import { createApp, nextTick } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import { monetaryMixin } from './mixins/monetary'
import { dateMixin } from './mixins/date'
import { toastMixin } from './mixins/toastMixin'

import 'vue3-toastify/dist/index.css';
import "@/assets/css/styles.css";

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

import 'vuetify/styles'
import '@mdi/font/css/materialdesignicons.css'

import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { aliases, mdi } from 'vuetify/iconsets/mdi'
import { fa } from 'vuetify/iconsets/fa'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faEnvelope, 
    faLock, 
    faMobileScreenButton, faArrowLeft, faUser, faUserPlus, faPen, faPlus, faChevronDown, faMagnifyingGlass, faCalendar, faLocationDot, faTrash, faCalendarPlus, faChevronRight, faGem, faCreditCard } from '@fortawesome/free-solid-svg-icons'

import { faCreditCard as faCreditCardOutline } from '@fortawesome/free-regular-svg-icons'

import { faPix } from '@fortawesome/free-brands-svg-icons'

library.add(
    faEnvelope, 
    faLock, 
    faMobileScreenButton, 
    faArrowLeft, 
    faUser, 
    faUserPlus, 
    faPen, 
    faPlus, 
    faChevronDown, 
    faMagnifyingGlass, 
    faCalendar, 
    faLocationDot,
    faTrash,
    faCalendarPlus,
    faChevronRight,
    faGem,
    faPix,
    faCreditCard,
    faCreditCardOutline
)

import { VueMaskDirective } from 'v-mask';
import { VueClipboard } from '@soerenmartius/vue3-clipboard'

const vMaskV2 = VueMaskDirective;
const vMaskV3 = {
    beforeMount: vMaskV2.bind,
    updated: vMaskV2.componentUpdated,
    unmounted: vMaskV2.unbind
};

// eslint-disable-next-line no-unused-vars
router.afterEach((to, from) => {
    nextTick(() => {
        document.title = 'Quero mais Rodeios :: ' + to.meta.title ?? 'Quero mais Rodeios';
    });
});



const vuetify = createVuetify({
    components,
    directives,
    icons: {
        defaultSet: 'mdi',
        aliases,
        sets: {
            mdi, fa
        }
    }
})

createApp(App)
    .component('icon', FontAwesomeIcon)
    .use(vuetify)
    .use(VueClipboard)
    .mixin(monetaryMixin)
    .mixin(dateMixin)
    .mixin(toastMixin)
    .directive('mask', vMaskV3)
    .use(store)
    .use(router)
    .mount('#app')
