const routes = [
    {
        path: '/admin',
        name: 'admin',
        component: () => import('../views/admin/main/MainHolder.vue'),
        meta: { title: 'Listagem de eventos', isAdmin: true, needAuth: true },
        children: [
            {
                path: 'users',
                name: 'users.list.view',
                component: () => import('../views/admin/main/UserListView.vue'),
                meta: { title: 'Gestão de usuário', isAdmin: true, needAuth: true },
            },
            {
                path: 'users/new',
                name: 'user.new.view',
                component: () => import('../views/admin/main/UserCreateView.vue'),
                meta: { title: 'Novo usuário', isAdmin: true, needAuth: true }
            },
            {
                path: 'users/id/:id',
                name: 'user.edit.view',
                component: () => import('../views/admin/main/UserCreateView.vue'),
                meta: { title: 'Editar usuário', isAdmin: true, needAuth: true }
            },
            {
                path: 'profile',
                name: 'profile.edit.view',
                component: () => import('../views/admin/main/MyProfileView.vue'),
                meta: { title: 'Meu perfil', isAdmin: true, needAuth: true }
            },
            {
                path: 'ctgs',
                name: 'ctg.register.view',
                component: () => import('../views/admin/main/CTGListView.vue'),
                meta: { title: 'Cadastro de CTGs', isAdmin: true, needAuth: true }
            },
            {
                path: 'events',
                name: 'event.report.view',
                component: () => import('../views/admin/main/EventReportView.vue'),
                meta: { title: 'Relatório de eventos', isAdmin: true, needAuth: true }
            },
        ]
    },
];

export default routes;