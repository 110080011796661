import { toast } from 'vue3-toastify';

export const TOAST = {
    SUCCESS: 'success',
    ERROR: 'error'
}

export const toastMixin = {
    methods: {
        toast(type, message, clearOnUrlChange = true, closeIn = 2000) {
            toast[type](
                message, 
                {
                    theme: 'dark', 
                    clearOnUrlChange: clearOnUrlChange,
                    dangerouslyHTMLString: true, 
                    autoClose: closeIn,
                    pauseOnFocusLoss: false
                }
            )

            return
        }
    }
}