import AuthService from '../services/auth.service';

const session = JSON.parse(localStorage.getItem('access_token'));
let user = JSON.parse(localStorage.getItem('user'));
let access_token = null;
let token = null

if (session) {
    access_token = session
    token = AuthService.parseJwt(access_token)
}

const initialState = user
? { status: { loggedIn: true }, user, access_token, token }
: { status: { loggedIn: false }, user: null, access_token: null, token: null };

export const auth = {
    namespaced: true,
    state: initialState,
    actions: {
        login({ commit }, user) {
            return AuthService.login(user).then(
                async user => {
                    let access_token = user.access_token
                    const parsed = AuthService.parseJwt(access_token)
                    
                    commit('loginSuccess', { user, parsed, access_token });
                    return Promise.resolve(user);
                },
                error => {
                    commit('loginFailure');
                    return Promise.reject(error);
                }
            );
        },
        logout({ commit }) {
            AuthService.logout();
            commit('logout');
        },
    },
    mutations: {
        loginSuccess(state, { user, parsed, access_token }) {
            state.status.loggedIn = true;
            state.user = user;
            state.access_token = access_token
            state.token = parsed
        },
        loginFailure(state) {
            state.status.loggedIn = false;
            state.user = null;
            state.access_token = null;
            state.token = null;
        },
        logout(state) {
            state.status.loggedIn = false;
            state.user = null;
            state.access_token = null;
            state.token = null;
        }
    },
    getters: {
        getExpiration: (state) => {
            return state.token?.exp ? state.token?.exp * 1000 : 0
        },

        getLoggedUser: (state) => {
            return state.user
        },
    }
};