const routes = [
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/user/login/LoginHolder.vue'),
        children: [
            {
                path: '',
                name: 'login.view',
                component: () => import('../views/user/login/LoginView.vue'),
                meta: { title: 'Login' }
            },
        ]
    },

    {
        path: '/:ctg/register',
        name: 'register.view',
        component: () => import('../views/user/login/RegisterView.vue'),
        meta: { title: 'Cadastro' }
    },
    
    {
        path: '/admin/login',
        name: 'admin.login',
        component: () => import('../views/admin/login/LoginHolder.vue'),
        children: [
            {
                path: '',
                name: 'admin.login.view',
                component: () => import('../views/admin/login/LoginView.vue'),
                meta: { title: 'Administrador' }
            },
            {
                path: 'forgot-password',
                name: 'admin.forgot-password.view',
                component: () => import('../views/admin/login/ForgotPasswordView.vue'),
                meta: { title: 'Esqueci minha senha' }
            },
            {
                path: 'forgot-password/email/:email',
                name: 'admin.forgot-password.email.view',
                component: () => import('../views/admin/login/ForgotPasswordEmailView.vue'),
                meta: { title: 'Esqueci minha senha' }
            },
            {
                path: 'forgot-password/phone/:phone',
                name: 'admin.forgot-password.phone.view',
                component: () => import('../views/admin/login/ForgotPasswordPhoneView.vue'),
                meta: { title: 'Esqueci minha senha' }
            },
            {
                path: 'reset-password',
                name: 'admin.reset-password.view',
                component: () => import('../views/admin/login/ResetPasswordView.vue'),
                meta: { title: 'Recuperação de senha' }
            },
        ]
    },
];

export default routes;