import moment from "moment"

export const dateMixin = {
    methods: {
        fromISO8601(isoString, format = 'DD/MM/YYYY HH:mm:ss') {
            return moment(isoString).format(format)
        },

        toISO8601(dateAsString) {
            return moment(dateAsString, 'DD/MM/YYYY HH:mm:ss').toISOString()
        },

        checkDate(date, format = 'DD/MM/YYYY HH:mm:ss') {
            return moment(date, format).isValid()
        },

        isToday(date) {
            const dateFromISO = new Date(date);
            const today = new Date();
        
            return (
                dateFromISO.getFullYear() === today.getFullYear() &&
                dateFromISO.getMonth() === today.getMonth() &&
                dateFromISO.getDate() === today.getDate()
            )
        }
    }
}